/*----------------------- custom checkbox --------------------------*/
/* The container */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkMark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 0.25rem;
    border: 2px double #141414;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkMark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a green background */
.container input:checked ~ .checkMark {
    background-color: #018d01;;
}

/* Create the checkMark/indicator (hidden when not checked) */
.checkMark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkMark when checked */
.container input:checked ~ .checkMark:after {
    display: block;
}

/* Style the checkMark/indicator */
.container .checkMark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* ------------------------------------------------------------ */

.confirm{
    margin-bottom: 1rem;
    box-shadow: inset 0 0 65px -20px white;
    border-radius: 0.5rem;
}

