.logout{
    position: absolute;
    top: 0;
    right: 0;
    height: 60px;
    background-color: #5e6b79;
    width: 150px;
    border: 1px solid;
    border-radius: 0.15rem;
    cursor: pointer;
}

.menu div a{
    color: white;
}

.menu div a:hover{
    color: whitesmoke;
    background-color: grey;
}

.menu  div a:active{
    color: whitesmoke;
    background-color: darkgrey;
}


.menu div div a {
    color: black;
}

.menu div div a:hover{
    color: white;
    background-color: grey;
}

.menu div div a:active{
    color: whitesmoke;
    background-color: darkgrey;
}

.menu div{
    border: 1px solid whitesmoke;
}

.logout{
    position: relative;
    margin-left: auto;
}

.navContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #636363;
    height: 3.9rem;
    box-shadow: 0 0 20px 0 white;
}






