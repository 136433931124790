#root{
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  font-size: 1.8rem;
  color: white;
}

.panel-container{
  transform: scale(1);
  -moz-transform: scale(1);
}

.plugin-container{
  background-color: #181b1e;
  color: white;
  width: 100%;
  height: 360px;
  overflow: auto;
  border: 1px solid white;
  border-radius: 3px;
  box-shadow: inset 0 0 20px 0 whitesmoke;
  margin: auto;
}

input{
  display: block;
  margin: auto auto 10px;
}

::placeholder {
  color: #9e9e9e;
  font-size: 1.4rem;
}

.main-container{
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.component{
  box-shadow: inset 0 0 20px 0;
  border: 1px solid;
  border-radius: 8px;
  background-color: #0c0a0a;
  width: 100%;
}

.user {
  box-shadow: inset 0 0 20px 0;
  border: 1px solid;
  border-radius: 8px;
  background-color: #0c0a0a;
}

.footer p {
  margin-top: 0;
  margin-bottom: 0;
}

.footer-message{
  padding-top: 1.5rem;
  font-size: 80%;
  color: white;
}

.footer-logo {
  padding-top: 0;
  font-size: 75%;
  color: white;
  text-shadow: 2px 2px 25px #ffffff;
  box-shadow: 18px 9px 23px -9px #100909;
}

.footer {
  position: sticky;
  top: 100vh;
  min-height: 8vh;
  background-color: #515151;
  margin-top: 2rem;
  box-shadow: inset -33px -27px 93px -47px white;
}

.login-container{
  min-height: 88vh;
}

.spin-image{
  padding-top: 0;
  width: 3rem;
}


.btn-outline-secondary{
  font-size: 100%;
}

h2{
  margin-bottom: 1rem;
}

.popover-header{
  color: #1d2124cf;
  background-color: #dddddd;
}

select:not(:-internal-list-box) {
  overflow: auto !important;
}


.App-logo {
  width: 4rem;
  pointer-events: none;
  z-index: 999999;
}

.logo-container{
  height: 4rem;
}

.login-logo-container{
  height: 7rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation-iteration-count: 1;
    animation: App-logo-spin  60s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.display-1 {
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.2;
  text-shadow: 0 0 20px;
  animation: pulsate 5.2s linear infinite;
  color: white;
  margin: 0 0.5rem;
}

.display-1-shadow {
  box-shadow: 0 323px 160px 35px #f8f9fa69;
}

.main-shadow{
  box-shadow: 409px -20px 160px 35px #f8f9fa69;
  height: 360px;
}


.o-non{
  font-size: 0.01rem;
  color: #818181;
}

.fixed-top {
  top: 0;
  z-index: 0;
}

.nav-tabs {
  border-bottom: 0 solid #dee2e6;
}

.nav-fill .nav-item {
  flex: 0 0 auto;
  text-align: center;
  box-shadow: -10px -20px 86px 1px white;
  border: 1px solid #e8e6df;
}

.nav-link {
  display: block;
  padding: 0.5rem 2.7rem;
}

 a.display-1:link {
  text-decoration: none;
}

a.display-1:hover {
  color: #eaeaea;
}

.btn-login-register{
  width: 21rem;
  margin: auto;
}

.login-background-color{
  background-color: #2b2b2b;
  box-shadow: 10px 19px 20px 20px #000000;
}

.login-size{
  font-size: 6rem;
}

.login-title{
  margin-top: 2rem;
  box-shadow: 0 -70px 20px -16px white;
}

.input-style {
  width: 25rem;
  height: 2.8rem;
  margin: 0 0.1rem 0 0;
}

.logout-padding{
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  font-size: 1rem;
}

.custom-danger{
  width: 5rem;
  border-color: #ffafb7;
  color: white;
}

.custom-success{
  width: 5rem;
  color: white;
  border-color: #a1dcaf;
}

.custom-info{
  width: 5rem;
  color: white;
  border-color: #83dfed;
}

.board{
  background-color: #181b1e;
  color: white;
  width: 640px;
  height: 360px;
  overflow: auto;
  border: 1px solid white;
  border-radius: 3px;
  box-shadow: inset 0 0 20px 0 whitesmoke;
  margin: auto;
}

.board-message{
  width: 60rem;
  height: 10rem;
  text-align: left;
}

.navbar-toggler {
  background-color: #ffffff59;
}

.navbar-light .navbar-toggler {
  border-color: var(--white);
  margin-left: 1rem;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--white);
}


.nav-link {
  display: block;
  padding: 0.5rem 2.7rem;
}



@media (min-width: 992px) {
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 2.7rem;
    padding-left: 2.7rem;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 3.7rem;
    padding-left: 3.7rem;
  }
}

.dropdown-menu.show {
  display: block;
  width: 100%;
}

.dropdown-item {
  padding: 0.25rem 0.5rem;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #2f3d4d;
}

.navbar {
  padding: 0;
  box-shadow: 0 0 20px 0 white;
}

.navbar-light .navbar-nav .nav-link{
  color: white;
}

.navbar-light .navbar-nav .nav-link:focus{
  color: black;
  background-color: white;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: black;
  background-color: white;
}

.navbar.bg-dark {
  background-color: #676767!important;
}

.navbar-light .navbar-toggler-icon{
  background-image: url("/public/menu.png")!important;
}


@media(min-width: 1200px) and (max-width: 1520px) {

}

@media(min-width: 992px) and (max-width: 1199px) {
  .board-message {
    width: 58rem;
  }
}

@media(min-width: 768px) and (max-width: 991px){
  .panel-container{
    transform: scale(0.8);
    -moz-transform: scale(0.8);
  }
  .board-message {
    width: 43rem;
  }
}

@media(min-width: 562px) and (max-width: 767px){
  .panel-container{
    transform: scale(0.6);
    -moz-transform: scale(0.6);
  }
  .display-1 {
    font-size: 3rem;
  }
  .App-logo{
    width: 3rem;
  }
  .logout-padding{
    font-size: 0.7rem;
  }
  .board-message {
    width: 32rem;
  }
}

@media(min-width: 300px) and (max-width: 561px){
  .panel-container{
    transform: scale(0.39);
    -moz-transform: scale(0.39);
  }
  .display-1 {
    font-size: 1.8rem;
  }
  .App-logo{
    width: 1.8rem;
  }
  .logout-padding{
    font-size: 0.4rem;
  }
  .board-message {
    width: 26rem;
  }
}

@media(min-width: 300px) and (max-width: 425px){
  .board-message {
    width: 22rem;
  }
}

.board-shadow{
  box-shadow: 285px 20px 2240px -100px #949494;
}


@media(min-width: 1200px) and (max-width: 1320px) {
  .display-1 {
    font-size: 2.8rem;
  }
  .App-logo{
    width: 2.8rem;
  }
}

.navbar-collapse {
  flex-grow: 0;
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    justify-content: space-between;
  }
}

