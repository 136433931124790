.optionClick{
   cursor: pointer;
}

.optionPlaceHolder{
   color: #9e9e9e;
   font-size: 1.4rem;
}

.container{
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   width: 917px;
   box-shadow: 140px 136px 10220px -60px;
}

.player{
   background-color: #181b1e;
   box-shadow: 0 0 5px 0;
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin-right: 4px;
}

.info{
   background-color: #282c34;
   border: 1px solid;
   box-shadow: inset 0 0 20px;
   width: 273px;
}

.action{
   background-color: #282c34;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid;
   box-shadow: inset 0 0 20px;
   width: 917px;
   height: 65px;
}

.dropdownButton{
   font-size: 65%;
}

.black{
   color: black;
}

.grey{
   color: #63666c;
   font-weight: 100;
}

.fieldList{
   width: 28rem;
}

.more{
   cursor: pointer;
   border: 1px solid;
   border-radius: 17px;
   background-color: #464d53
}

.titleMore{
   color: #1d2124cf;
   font-weight: 700;
}

.more:hover {
   transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
   background-color: #5e6b79;
}
.more:active {
   top: 1px;
   background-color: #5e6b79;
}

.playButton{
   width: 4.5rem;
}

.safetyMore{
   font-size: 65%;
   display: inline;
}

.moderator{
   width: 25rem;
}

.bottomForm{
   display: flex;
   justify-content: center;
   margin-top: 0.7rem;
   border: 1px solid #49505769;
   padding: 10px 0;
   box-shadow: 9px 26px 107px 20px black;
   border-bottom-left-radius: 2rem;
   border-bottom-right-radius: 2rem;
}

.topForm{
   display: flex;
   justify-content: center;
   box-shadow: 0 0 200px 0 #dee2e6;
   margin-bottom: 0.7rem;
   padding: 10px 0;
   background-color: #343a40;
   border-top-left-radius: 2rem;
   border-top-right-radius: 2rem;
}

.origins{
   width: 40rem;
}

.selectInfo{
   width: 16.8rem;
}
