.board{
    background-color: #181b1e;
    color: white;
    width: 640px;
    height: 360px;
    overflow: auto;
    border: 1px solid white;
    border-radius: 3px;
    box-shadow: inset 0 0 20px 0 whitesmoke;
    margin: auto;
}

.message{
    padding-top: 130px;
}

